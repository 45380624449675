import * as THREE from "three";
import gsap from 'gsap';

import Experience from "./Experience";
import EventEmitter from "./Utils/EventEmitter";

let listenForInteractions, currentLocation = 'institutional';

export default class Audio extends EventEmitter
{
    // Set constructor
    constructor()
    {
        // Extends the EventEmitter class
        super();

        // Get the experience instance
        this.experience = new Experience();
        // Get the needed classes from the experience
        this.resources = this.experience.resources;

        // Set audios object
        this.audios = {};
        this.playing = false;
        this.lastPianoNote = null;

        // Set audio listener
        this.listener = new THREE.AudioListener();

        // Listen to when the resources are loaded
        this.resources.on('loadedResources', () =>
        {
            // Set the audio listener
            this.#setAudioListener();
            // Set ambiance sound
            this.#setAmbiance();
            // Set SFX
            this.#setSFX();
        });
    }

    // Private method called to set up the audio listener
    #setAudioListener()
    {
        // Get the camera class from the experience
        this.camera = this.experience.camera;
        // Add to the camera
        this.camera.renderCamera.add(this.listener);
        // Remove reference
        this.camera = null;
    }

    // Private method called to set up the ambiance sound
    #setAmbiance()
    {
        // Set the audio button configuration
        this.buttonConfig = true;

        // Set the default soundtrack
        this.audios.soundtrack = new THREE.Audio(this.listener);
        this.audios.soundtrack.setBuffer(this.resources.items.soundtrack_institutional);
        this.audios.soundtrack.setLoop(true);

        // Set the ambiance soundtrack for external areas
        this.audios.soundtrack_external = new THREE.Audio(this.listener);
        this.audios.soundtrack_external.setBuffer(this.resources.items.soundtrack_external);
        this.audios.soundtrack_external.setLoop(true);

        // Set callback for the listeners
        listenForInteractions = () =>
        {
            // Play audio
            this.audios.soundtrack.play();
            this.audios.soundtrack_external.play();
            this.playing = true;

            // Remove listeners
            document.removeEventListener('keydown', listenForInteractions);
            document.removeEventListener('pointerdown', listenForInteractions);
            document.removeEventListener('touchstart', listenForInteractions);
        }

        // Listen for any user interaction
        document.addEventListener('keydown', listenForInteractions);
        document.addEventListener('pointerdown', listenForInteractions);
        document.addEventListener('touchstart', listenForInteractions);
    }

    // Private method called to set the SFX tracks
    #setSFX()
    {
        // Create walk footstep
        this.audios.tagEffect = new THREE.Audio(this.listener);
        this.resources.loaders.audioLoader.load(
            // Asset adress
            window.ASSETS_PATH + 'audio/sfx/sfx_tag_de_localizacao.mp3',
            // Finished loading
            (buffer) => { this.audios.tagEffect.setBuffer(buffer) }
        );

        // Create book effects
        this.audios.bookEffect = new THREE.Audio(this.listener);
        this.resources.loaders.audioLoader.load(
            // Asset adress
            window.ASSETS_PATH + 'audio/sfx/sfx_coletar_livro.mp3',
            // Finished loading
            (buffer) => { this.audios.bookEffect.setBuffer(buffer) }
        );

        // Create book effects
        this.audios.bookModalEffect = new THREE.Audio(this.listener);
        this.resources.loaders.audioLoader.load(
            // Asset adress
            window.ASSETS_PATH + 'audio/sfx/sfx_modal_entrega_brinde_digital.mp3',
            // Finished loading
            (buffer) => { this.audios.bookModalEffect.setBuffer(buffer) }
        );

        // Create elevator starting effects
        this.audios.elevatorStartEffect = new THREE.Audio(this.listener);
        this.resources.loaders.audioLoader.load(
            // Asset adress
            window.ASSETS_PATH + 'audio/sfx/sfx_elevador_ativado.mp3',
            // Finished loading
            (buffer) => { this.audios.elevatorStartEffect.setBuffer(buffer) }
        );

        // Create elevator starting effects
        this.audios.elevatorEffect = new THREE.Audio(this.listener);
        this.resources.loaders.audioLoader.load(
            // Asset adress
            window.ASSETS_PATH + 'audio/sfx/sfx_elevador_em_movimento.mp3',
            // Finished loading
            (buffer) => { this.audios.elevatorEffect.setBuffer(buffer) }
        );

        // Create the spaceshift effects
        this.audios.spaceshipEffect = new THREE.Audio(this.listener);
        this.resources.loaders.audioLoader.load(
            // Asset adress
            window.ASSETS_PATH + 'audio/sfx/sfx_foguete_voando_literatura.mp3',
            // Finished loading
            (buffer) => { this.audios.spaceshipEffect.setBuffer(buffer) }
        );

        // Create the racing car effects
        this.audios.raceCarEffect = new THREE.Audio(this.listener);
        this.resources.loaders.audioLoader.load(
            // Asset adress
            window.ASSETS_PATH + 'audio/sfx/sfx_carro_sistema_ensino_ftd.mp3',
            // Finished loading
            (buffer) => { this.audios.raceCarEffect.setBuffer(buffer) }
        );
        this.audios.raceCarEffect.setVolume(0);

        // Set footsteps
        this.audios.footsteps = [[], []];
        // For each of the footstep audios
        for(let i = 0; i < 4; i++)
        {
            // Create walk footstep
            const walkFootstep = new THREE.Audio(this.listener);
            this.resources.loaders.audioLoader.load(
                // Asset adress
                window.ASSETS_PATH + 'audio/footsteps/steps_andando_' + i + '.mp3',
                // Finished loading
                (buffer) =>
                {
                    // Set buffer and volume
                    walkFootstep.setBuffer(buffer);
                    walkFootstep.setVolume(1);

                    // Add to the array
                    this.audios.footsteps[0].push(walkFootstep);
                }
            );

            // Create run footstep
            const runFootstep = new THREE.Audio(this.listener);
            this.resources.loaders.audioLoader.load(
                // Asset adress
                window.ASSETS_PATH + 'audio/footsteps/steps_correndo_' + i + '.mp3',
                // Finished loading
                (buffer) =>
                {
                    // Set buffer and volume
                    runFootstep.setBuffer(buffer);
                    runFootstep.setVolume(1);

                    // Add to the array
                    this.audios.footsteps[1].push(runFootstep);
                }
            );
        }

        // Create piano note effects
        this.audios.notes = [];
        // For each of the footstep audios
        for(let i = 1; i < 9; i++)
        {
            // Create walk footstep
            const pianoNote = new THREE.Audio(this.listener);
            this.resources.loaders.audioLoader.load(
                // Asset adress
                window.ASSETS_PATH + 'audio/sfx/piano_' + i + '.mp3',
                // Finished loading
                (buffer) =>
                {
                    // Set buffer and volume
                    pianoNote.setBuffer(buffer);
                    // Add to the array
                    this.audios.notes[i - 1] = pianoNote;
                }
            );
        }
    }

    // Method called to change the soundtrack accordingly to the player location
    changeSoundtrack(name)
    {
        // Get location name
        let location = name;
        if(location === "start") location = "institutional";
        else if(location === "outside") location = "sde";

        // If the audio can play
        if(this.buttonConfig === true)
        {
            // Play tag SFX
            this.audios.tagEffect.play();

            // If the current location is different from the new location
            if(currentLocation !== location)
            {
                // Update current location
                currentLocation = location;
                // Set volume parameter
                let parameter = { 'volume': 1 };

                // Tween the volume parameter
                gsap.to(parameter, { volume: 0, duration: 1, yoyo: true, repeat: 1, onUpdate: () =>
                {
                    // Update audio volume
                    this.audios.soundtrack.setVolume(parameter.volume);

                    // If the audio is almost muted
                    if(parameter.volume < 0.1)
                    {
                        // Change the soundtrack track
                        this.audios.soundtrack.stop();
                        this.audios.soundtrack.setBuffer(this.resources.items["soundtrack_" + location]);
                        this.audios.soundtrack.setVolume(parameter.volume);
                        this.audios.soundtrack.play();
                    }
                } });
            }

            // If the player is in an open area, play the external soundtrack
            if(name === "start" || name === "outside") this.audios.soundtrack_external.play();
            // If the user is in a closed area, stop the external soundtrack
            else this.audios.soundtrack_external.stop();
        }
        // If the audio can't play
        else
        {
            // Only change the soundtrack
            this.audios.soundtrack.setBuffer(this.resources.items["soundtrack_" + location]);
        }
    }

    // Method called to play a footstep audio
    playFootstep(running)
    {
        // If the audio effects can be played
        if(this.buttonConfig === true)
        {
            // Set type of footstep
            let type = 0;
            if(running === true) type = 1;

            // Set min value
            const min = Math.ceil(0);
            // Set max value
            const max = Math.floor(3);

            // Get random number between min and max
            const audioId = Math.floor(Math.random() * (max - min + 1)) + min;

            if(this.audios.footsteps[type][audioId].isPlaying === true) this.audios.footsteps[type][audioId].stop();
            // Play the footstep audio
            this.audios.footsteps[type][audioId].play();
        }
    }

    // Method called to play the elevator effects
    playElevatorEffects()
    {
        if(this.buttonConfig === true)
        {
            // Play the starting elevator effect
            this.audios.elevatorStartEffect.play();

            setTimeout(() =>
            {
                // Play the elevator effect
                this.audios.elevatorEffect.play();
            }, 200);
        }
    }

    // Method called to play the piano notes
    playPianoNote(number)
    {
        if(this.buttonConfig === true)
        {
            // If the current note isn't the same as the new note
            if(number !== this.lastPianoNote)
            {
                // Get new note
                this.lastPianoNote = number;
                // Play note
                this.audios.notes[number].play();
            }
        }
    }

    // Method called to pause or resume the ambient soundtrack
    pauseOrResumeSoundtrack(bool)
    {
        // If the variable sent isn't null or undefined
        if(bool !== null && bool !== undefined)
        {
            // If the variable is true
            if(bool === true)
            {
                // Resume the song
                this.audios.soundtrack.play();
                if(this.experience.player.instance.location === 'start' || this.experience.player.instance.location === 'outside')
                {
                    // Resume the external soundtrack
                    this.audios.soundtrack_external.play();
                }

                this.playing = true;
            }
            // If the variable is false
            else
            {
                // Pause the song
                this.audios.soundtrack.stop();
                if(this.audios.soundtrack_external.isPlaying === true)
                {
                    // Pause the external soundtrack
                    this.audios.soundtrack_external.stop();
                }

                this.playing = false;
            }
        }
        // If the variable sent is null or undefined
        else
        {
            // If the song is playing
            if(this.audios.soundtrack.isPlaying === true)
            {
                // Pause the song
                this.audios.soundtrack.stop();
                if(this.audios.soundtrack_external.isPlaying === true)
                {
                    // Pause the external soundtrack
                    this.audios.soundtrack_external.stop();
                }

                this.playing = false;
            }
            // If the song isn't playing
            else
            {
                // Resume the song
                this.audios.soundtrack.play();
                if(this.experience.player.instance.location === 'start' || this.experience.player.instance.location === 'outside')
                {
                    // Resume the external soundtrack
                    this.audios.soundtrack_external.play();
                }

                this.playing = true;
            }
        }
    }
}