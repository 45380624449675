import * as THREE from "three";
import { Capsule } from 'three/examples/jsm/math/Capsule.js';
import * as SkeletonUtils from 'three/examples/jsm/utils/SkeletonUtils.js';

import Experience from "../Experience.js";
import EventEmitter from '../Utils/EventEmitter.js';

// Time variable
let delta, isMobile, running = false;
let elevatorCooldown = false, footstepCooldown = false, previousHeight = -100;

// Location constants
const startArea = 'start';
const institutionalArea = 'institutional';
const ionicArea = 'ionic';
const literatureArea = 'literature';
const sdeArea = 'sde';
const outsideArea = 'outside';

// Gravity force intensity
const GRAVITY = 20;
// Times the movement function will repeat every frame
const STEPSPERFRAME = 5;

export default class Player extends EventEmitter
{
    // Set constructor
    constructor()
    {
        // Extends the EventEmitter class
        super();

        // Get the experience instance
        this.experience = new Experience();
        // Get the needed classes from the experience
        this.pointer = this.experience.pointer;
        this.camera = this.experience.camera;
        this.composer = this.experience.composer;
        this.colliders = this.experience.colliders;
        this.resources = this.experience.resources;
        this.audio = this.experience.audio;
        this.scene = this.experience.scene;
        this.building = this.experience.building;

        // Create raycast
        this.raycaster = new THREE.Raycaster();

        // Create local clock for the animations
        this.clock = new THREE.Clock();
    }

    // Private method called to create the player instance
    setPlayer()
    {
        // Create new instance
        this.instance = {};
        this.instance.animations = {};
        this.instance.hoveredElem = null;
        this.instance.location = startArea;

        // Set book collection variables
        this._numberOfBooks = 6;
        this.collectedBooks = 0;

        // Create player collider
        this.instance.collider = new Capsule(new THREE.Vector3(-8, 1.5, 0), new THREE.Vector3(-8, 2.5, 0), 0.75 );

        // Create velocity and direction vectors
        this.instance.velocity = new THREE.Vector3();
        this.instance.direction = new THREE.Vector3();
        // Movement variables
        this.instance.moving = false;
        this.instance.onFloor = false;
        this.instance.usingElevator = false;

        // Get model from the resources
        const gltf = this.resources.items.npc;
        // Clone skinned mesh model
        this.instance.model = SkeletonUtils.clone(gltf.scene);
        this.instance.model.rotation.y = Math.PI * 0.65;

        // Set animation mixer
        this.instance.animations.mixer = new THREE.AnimationMixer(this.instance.model);
        // Set subclip animations
        this.instance.animations.idle = this.instance.animations.mixer.clipAction(THREE.AnimationUtils.subclip(gltf.animations[0], 'idle', 67, 319));
        this.instance.animations.walking = this.instance.animations.mixer.clipAction(THREE.AnimationUtils.subclip(gltf.animations[0], 'walking', 0, 33.6));
        this.instance.animations.running = this.instance.animations.mixer.clipAction(THREE.AnimationUtils.subclip(gltf.animations[0], 'running', 37, 61));
        this.instance.animations.texting = this.instance.animations.mixer.clipAction(THREE.AnimationUtils.subclip(gltf.animations[0], 'texting', 320, 644));

        // Play idle animation
        this.instance.animations.idle.play();

        // Go through all the model's children
        this.instance.model.traverse((child) =>
        {
            // If child is a mesh object and their material is a standard material
            if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
            {
                // Fix child from disappearing when outside the camera frustrum
                child.frustumCulled = false;
            }
        });

        // Add character model to the scene
        this.scene.add(this.instance.model);

        // Set the camera collisions for the player movement
        this.camera.setCameraCollisions();

        // Remove reference
        this.scene = null;
        this.resources = null;

        // Get the mobile detector class from the experience
        this.mobileDetector = this.experience.mobileDetector;
        // Verify if the device is a mobile
        isMobile = this.mobileDetector.isMobile;

        // If the device is a mobile
        if(isMobile === true)
        {
            // Get the joystick class from the experience
            this.joystick = this.experience.joystick;
        }
        // If the device is a desktop
        else
        {
            // Get the key class from the experience
            this.keys = this.experience.keys;
        }

        // Listen for touch event
        this.pointer.on('pointerTouch', () =>
        {
            // Cast a raycast
            this.#castRaycast(this.pointer.mouse);

            // If the device is a mobile
            if(isMobile === true)
            {
                // If the user is touching a stand during the click
                if(this.instance.hoveredElem !== null)
                {
                    // Data to be sent
                    let data = { 'clicked_elem': this.instance.hoveredElem };

                    // Trigger event warning that an object was clicked, sending the info acquired
                    const clickEvent = new CustomEvent( 'interface3DClickEvent', { detail: data } );
                    window.dispatchEvent(clickEvent);

                    // Reset variable
                    this.instance.hoveredElem = null;
                }
            }
        });

        this.setInteractables();
    }

    // Method called to set up the interactable objects
    setInteractables()
    {
        // Save arrays
        this.instance.interactables = this.building.instance.interactables;
        this.instance.collideables = this.building.instance.collectableBooks;

        // Create a new array for the objects that will be interacted via raycast
        this.instance.interactableModels = [];

        for(let i = 0; i < this.instance.interactables.length; i ++)
        {
            // Add the 3D object to array
            this.instance.interactableModels[i] = this.instance.interactables[i].object;
        }
    }

    // Private method called to cast a new raycast
    #castRaycast(coords)
    {
        // Set raycaster
        this.raycaster.setFromCamera(coords, this.camera.renderCamera);
        const intersection = this.raycaster.intersectObjects(this.instance.interactableModels);

        // If the raycaster have intersected with the interactive elements
        if(intersection.length > 0)
        {
            // If the interactive object is close enought to the player
            if(intersection[0].distance < 20)
            {
                // Get the intersected object's name
                this.instance.hoveredElem = intersection[0].object.name;
            }
            // If the interactive object isn't close enought to the player
            else
            {
                // Remove the reference
                this.instance.hoveredElem = null;
            }
        }
        // If the raycaster haven't intersected with the interactive elements
        else
        {
            // Remove the reference
            this.instance.hoveredElem = null;
        }
    }

    #updateBooksCollection()
    {
        // For each of the collectable books
        this.instance.collideables.forEach(book =>
        {
            // If the book is close enough to the player
            if(book.testProximity(this.instance.collider.end) && book.collected === false)
            {
                // Get the book id
                const bookId = book.name.split('_')[2].split('0')[1];
                // Get the assigned place to the book after it has been collected
                const positionedBook = this.building.instance.positionedBooks[bookId - 1];
                positionedBook.visible = false;

                // Add one to the collected books variable
                this.collectedBooks++;
                document.getElementById('collected-books').innerHTML = '<b>' + this.collectedBooks + '</b> / 6';
                book.collected = true;

                // Play book collection SFX
                if(this.audio.buttonConfig === true) this.audio.audios.bookEffect.play();

                // Remove the book from the collectables array
                this.composer.outlinePass.selectedObjects = this.composer.outlinePass.selectedObjects.filter((value, index, arr) =>
                {
                    return value.name != book.name;
                });

                // Set book position, rotations and scale to fit inside the shelf
                book.object.position.copy(positionedBook.position);
                book.object.rotation.copy(positionedBook.rotation);
                book.object.scale.copy(positionedBook.scale);

                // Data to be sent
                let data = {
                    'book_id': bookId,
                    'book_name': book.name
                };

                // Trigger event warning that a book was collected, sending the info acquired
                const bookEvent = new CustomEvent( 'collected3DBookEvent', { detail: data } );
                window.dispatchEvent(bookEvent);

                // If the player collected all the books
                if(this.collectedBooks === this._numberOfBooks)
                {
                    // Call the gift modal management
                    this.pointer.manageGiftModal();

                    // Play book modals SFX
                    if(this.audio.buttonConfig === true) this.audio.audios.bookModalEffect.play();
                }
            }
        });
    }

    // Private method called to manage the piano notes
    #managePianoNotes()
    {
        // Create piano raycaster if needed
        if(!this.pianoRaycaster) this.pianoRaycaster = new THREE.Raycaster();

        // Set piano raycaster
        this.pianoRaycaster.set(this.instance.collider.start, new THREE.Vector3(0, -1, 0));
        const intersection = this.pianoRaycaster.intersectObjects(this.building.instance.pianoNotes);

        // If there is an intersection with the piano notes
        if(intersection.length > 0)
        {
            // Play audio
            this.audio.playPianoNote(intersection[0].object.name.split('0')[1] - 1);
        }
        // If there isn't an intersection, reset variable
        else this.audio.lastPianoNote = null;
    }

    // Method called to manage the proximity to the racing car
    #manageRaceCarProximity()
    {
        // If the player is inside the race car radius
        if(this.building.instance.raceCar.testProximity(this.instance.collider.end))
        {
            // If the audio isn't playing, play it
            if(this.audio.audios.raceCarEffect.isPlaying === false && this.audio.buttonConfig === true) this.audio.audios.raceCarEffect.play();
            else if(this.audio.audios.raceCarEffect.isPlaying === true && this.audio.buttonConfig === false) this.audio.audios.raceCarEffect.stop();

            // Get the distance to the race car
            let distance = this.instance.collider.end.distanceTo(this.building.instance.raceCar.object.position);
            // Get the volume based on the distance to the player
            let relativeVolume = 1.2 * (1 - distance / 8);
            // Set the volume to the audio track
            this.audio.audios.raceCarEffect.setVolume(relativeVolume);
        }
        // If the player isn't inside the race car radius
        else
        {
            // If the audio is playing, stop it
            if(this.audio.audios.raceCarEffect.isPlaying === true) this.audio.audios.raceCarEffect.stop();
        }
    }

    // Private method called to get the forward vector from the player
    #getForwardVector()
    {
        // Get normalized direction
        this.camera.renderCamera.getWorldDirection(this.instance.direction);
        this.instance.direction.y = 0;
        this.instance.direction.normalize();

        return this.instance.direction;
    }

    // Private method called to react to the key states
    #updateKeyStates(deltaTime)
    {
        // Set movement damping with a bit of air control
        let speedDelta = deltaTime * ( this.instance.onFloor ? 25 : 2 );

        // Initiate variables
        let W, A, S, D, SHIFT;

        // If the user is pressing the W or the Arrow Up key
        if(this.keys.keyStates['KeyW'] || this.keys.keyStates['ArrowUp']) W = true;
        // If the user is pressing the A or the Arrow Left key
        if(this.keys.keyStates['KeyA'] || this.keys.keyStates['ArrowLeft']) A = true;
        // If the user is pressing the S or the Arrow Down key
        if(this.keys.keyStates['KeyS'] || this.keys.keyStates['ArrowDown']) S = true;
        // If the user is pressing the D or the Arrow Right key
        if(this.keys.keyStates['KeyD'] || this.keys.keyStates['ArrowRight']) D = true;
        // If the user is pressing the Shift or the Spacebar key
        if(this.keys.keyStates['ShiftLeft'] || this.keys.keyStates['Space']) SHIFT = true;

        // If the character is moving forward and is not inside the elevator
        if(W === true && this.instance.usingElevator === false)
        {
            // If the character is running
            if(SHIFT === true)
            {
                speedDelta *= 1.75;
                running = true;
            }
            else running = false;

            this.instance.moving = true;
            this.instance.velocity.add(this.#getForwardVector().multiplyScalar(speedDelta));
        }
        // If the character is moving backwards and is not inside the elevator
        if(S === true && this.instance.usingElevator === false)
        {
            running = false;
            this.instance.moving = true;
            this.instance.velocity.add(this.#getForwardVector().multiplyScalar(-speedDelta));
        }
        // If the character is moving to the left
        if(A === true)
        {
            this.instance.model.rotation.y += 0.01;
        }
        // If the character is moving to the right
        if(D === true)
        {
            this.instance.model.rotation.y -= 0.01;
        }

        // If one of the movement keys is pressed
        if(this.instance.moving === true)
        {
            // If the character is moving backwards
            if(S === true)
            {
                // Set animation to play on reverse
                this.instance.animations.walking.setEffectiveTimeScale(-1);
            }
        }
    }

    // Private method called to react to the key states
    #updateJoystickValues(deltaTime)
    {
        // Set movement damping with a bit of air control
        let speedDelta = deltaTime * ( this.instance.onFloor ? 25 : 2 ) * Math.min(1, this.joystick.movementJoystick.force);

        // Get keys
        let W = false, A = false, S = false, D = false;

        // Move forwards when not inside the elevator
        if(this.joystick.movementJoystick.forwardValue >= 0.4 && this.instance.usingElevator === false)
        {
            W = true;
            this.instance.moving = true;

            // If the joystick is pushed further enough, set the player to run
            if(this.joystick.movementJoystick.force > 2.4)
            {
                // Multiply the speed
                speedDelta *= 1.75;
                running = true;
            }
            else running = false;

            // Add to the player speed
            this.instance.velocity.add(this.#getForwardVector().multiplyScalar(speedDelta));
        }
        // Move backwards when not inside the elevator
        if(this.joystick.movementJoystick.backwardValue >= 0.4 && this.instance.usingElevator === false)
        {
            S = true;
            this.instance.moving = true;
            // Add to the player speed
            this.instance.velocity.add(this.#getForwardVector().multiplyScalar(-speedDelta));
        }
        // Turn to the left
        if(this.joystick.movementJoystick.leftValue >= 0.45)
        {
            A = true;
            // Rotate player model
            this.instance.model.rotation.y += 0.0075;
        }
        // Turn to the right
        if(this.joystick.movementJoystick.rightValue >= 0.45)
        {
            D = true;
            // Rotate player model
            this.instance.model.rotation.y -= 0.0075;
        }

        // If one of the movement keys is pressed
        if(this.instance.moving === true)
        {
            // If the character is moving backwards
            if(S === true)
            {
                // Set animation to play on reverse
                this.instance.animations.walking.setEffectiveTimeScale(-1.25 * Math.min(1, this.joystick.movementJoystick.force));
            }
            // If the character is moving forward
            else if(W === true)
            {
                // Get the joystick relative speed
                const relSpeed = Math.min(1, this.joystick.movementJoystick.force);
                
                // Set the animation speed based on the joystick relative speed
                if(running === false) this.instance.animations.walking.setEffectiveTimeScale(1.25 * relSpeed);
                else this.instance.animations.running.setEffectiveTimeScale(1.4 * relSpeed);
            }
        }
    }

    // Private method called to get the player collisions
    #playerCollisions()
    {
        // Get the intersections between the player collider and the world collider
        const result = this.colliders.octree.capsuleIntersect(this.instance.collider);

        // Reset variable
        this.instance.onFloor = false;

        if(result)
        {
            // Verify if the player is on the ground
            this.instance.onFloor = result.normal.y > 0;

            // If the player isn't on the ground
            if(this.instance.onFloor === false)
            {
                // Add the effect of gravity upon the player
                this.instance.velocity.addScaledVector(result.normal, -result.normal.dot(this.instance.velocity));
            }

            // Don't allow the player to trespass the world colliders
            this.instance.collider.translate(result.normal.multiplyScalar(result.depth));
        }
    }

    // Private method called to update the player
    #updatePlayer(deltaTime)
    {
        // Movement damping
        let damping = Math.exp(-10 * deltaTime) - 1;

        // If the player isn't on the ground and isn't inside the elevator
        if(this.instance.onFloor === false && this.instance.usingElevator === false)
        {
            // Add the effect of gravity upon the player
            this.instance.velocity.y -= GRAVITY * deltaTime;

            // Add small air resistance
            damping *= 0.1;
        }

        // Set player velocity
        this.instance.velocity.addScaledVector(this.instance.velocity, damping);

        // Update player position
        const deltaPosition = this.instance.velocity.clone().multiplyScalar(deltaTime);
        this.instance.collider.translate(deltaPosition);

        // Check for collisions when not inside the elevator
        if(this.instance.usingElevator === false) this.#playerCollisions();

        // Update the camera position
        this.instance.model.position.copy(this.instance.collider.start);
        // Reduce the player position by the collider radius
        this.instance.model.position.y -= 0.75;

        if(this.instance.onFloor === true)
        {
            // If the character is moving but the walk animation isn't playing
            if(this.instance.moving === true)
            {
                if(running === false && this.instance.animations.walking.isRunning() === false)
                {
                    // If the other animations are running, stop them gradually
                    if(this.instance.animations.idle.isRunning()) this.instance.animations.idle.fadeOut(0.2);
                    if(this.instance.animations.running.isRunning()) this.instance.animations.running.fadeOut(0.2);

                    // Reset walk animation and fade in for a gradual start
                    this.instance.animations.walking.reset();
                    if(isMobile === false) this.instance.animations.walking.setEffectiveTimeScale(1.25);
                    this.instance.animations.walking.setEffectiveWeight(1)
                        .fadeIn(0.2)
                        .play();
                }
                else if(running === true && this.instance.animations.running.isRunning() === false)
                {
                    // If the other animations are running, stop them gradually
                    if(this.instance.animations.idle.isRunning()) this.instance.animations.idle.fadeOut(0.2);
                    if(this.instance.animations.walking.isRunning()) this.instance.animations.walking.fadeOut(0.2);

                    // Reset walk animation and fade in for a gradual start
                    this.instance.animations.running.reset();
                    if(isMobile === false) this.instance.animations.running.setEffectiveTimeScale(1.4);
                    this.instance.animations.running.setEffectiveWeight(1)
                        .fadeIn(0.2)
                        .play();
                }
            }
            // If the character isn't walking but the walk animation is playing
            else if(this.instance.moving === false && this.instance.animations.idle.isRunning() === false)
            {
                // If the walk animation is running
                if(this.instance.animations.walking.isRunning()) this.instance.animations.walking.fadeOut(0.2);
                if(this.instance.animations.running.isRunning()) this.instance.animations.running.fadeOut(0.2);

                // Reset idle animation and fade in for a gradual start
                this.instance.animations.idle.reset()
                    .setEffectiveTimeScale(1)
                    .setEffectiveWeight(1)
                    .fadeIn(0.2)
                    .play();
            }
        }
    }

    // Private method called to manage player location inside the four main areas
    #managePlayerLocation()
    {
        // Get player position
        const position = this.instance.collider.start;

        // If the player is inside the Literature area
        if(position.x < 28 && position.z < -18)
        {
            // If the player just entered this area
            if(this.instance.location !== literatureArea)
            {
                // Update player location
                this.instance.location = literatureArea;

                // Show the location tag with the current location
                this.#setLocationTag();
                
                // Set rocket to visible and play animation
                this.building.instance.rocket.visible = true;
                this.building.startStopClip(this.building.animations.rocketAnim, true);

                // Dissolve the room force field
                this.building.dissolveForceField(0);
            }
        }
        // If the player isn't inside the Literature area
        else
        {
            // If the player left the Literature area
            if(this.instance.location === literatureArea)
            {
                // Stop animation and deactivate the rocket
                this.building.instance.rocket.visible = false;
                this.building.startStopClip(this.building.animations.rocketAnim, false);
            }
            // If the player is inside the Ionic area
            if(position.x > 40 && (position.z < 20 && position.z > -20))
            {
                // If the player just entered this area
                if(this.instance.location !== ionicArea)
                {
                    // Update player location
                    this.instance.location = ionicArea;

                    // Show the location tag with the current location
                    this.#setLocationTag();
                }
            }
            // If the player is inside the SDE area
            else if(position.x < 40 && position.z > 16)
            {
                // If the player is on top of the SDE area
                if(position.y > 6.62)
                {
                    // If the player just entered this area
                    if(this.instance.location !== outsideArea)
                    {
                        // Update player location
                        this.instance.location = outsideArea;

                        // Show the location tag with the current location
                        this.#setLocationTag();
                    }
                }
                // If the player is inside de SDE area
                else
                {
                    // If the player just entered this area
                    if(this.instance.location !== sdeArea)
                    {
                        // Update player location
                        this.instance.location = sdeArea;

                        // Show the location tag with the current location
                        this.#setLocationTag();

                        // Dissolve the room force field
                        this.building.dissolveForceField(1);
                    }
                }
            }
            // If the player is inside the Institutional area
            else if((position.x > -2 && position.x < 32) && (position.z > -17 && position.z < 14) && position.y < 1.5)
            {
                // If the player just entered this area
                if(this.instance.location !== institutionalArea)
                {
                    // Update player location
                    this.instance.location = institutionalArea;

                    // Show the location tag with the current location
                    this.#setLocationTag();
                }
            }
            // If the player is inside the Starting area
            else if(position.x < -2 && position.z > -5)
            {
                // If the player just entered this area
                if(this.instance.location !== startArea)
                {
                    // Update player location
                    this.instance.location = startArea;

                    // Show the location tag with the current location
                    this.#setLocationTag();
                }
            }
        }

        // If the player is close enough to the elevator to use it
        if(this.building.instance.elevator[1].testProximity(this.instance.collider.start) && this.instance.usingElevator === false)
        {
            // If the elevator isn't on cooldown
            if(elevatorCooldown === false)
            {
                // Set variables to true
                this.instance.usingElevator = true;
                elevatorCooldown = true;

                // Use elevator
                this.building.useElevator(position);
                this.audio.playElevatorEffects();

                // Reset the variable after the elevator arrived
                setTimeout(() =>{
                    this.instance.usingElevator = false;
                }, 5000);
            }
        }
        // If the player is further enough to the elevator to reset it
        if(elevatorCooldown === true)
        {
            // Get the player distance to the elevator
            let distance = this.building.instance.elevator[1].object.position.distanceTo(this.instance.collider.start);
            // If the player is further enough to the elevator to reset it
            if(distance > 2 ) elevatorCooldown = false;
        }
    }

    // Private method called to set up and show the location tag with the current player location
    #setLocationTag()
    {
        // Update soundtrack
        this.audio.changeSoundtrack(this.instance.location);

        // Get informative tag
        let classElem = document.getElementsByClassName('informative-tag')[0];
        // Remove last location tag
        classElem.classList.forEach(name =>
        {
            if(name !== 'informative-tag') classElem.classList.remove(name);
        });

        // Add the respective area class and the opacity class to turn up the opacity
        classElem.classList.add('informative-tag--' + this.instance.location);
        classElem.classList.add('informative-tag--active');

        // Set timer of two seconds
        setTimeout(() =>
        {
            // Remove active class to turn down the opacity
            document.getElementsByClassName('informative-tag')[0].classList.remove('informative-tag--active');
        }, 3000);
    }

    // Private method called to manage the timing of the footsteps SFX
    #manageFootsteps()
    {
        // If the footstep cooldown isn't active
        if(footstepCooldown === false)
        {
            // If the player is walking
            if(running === false)
            {
                // Get rounded time of the animation
                const roundedTime = parseFloat(this.instance.animations.walking.time.toFixed(2));
                // When one of the model's feet is touching the ground
                if((roundedTime >= 0.45 && roundedTime <= 0.55) || (roundedTime >= 0.95 && roundedTime <= 1.05))
                {
                    // Set cooldown to true
                    footstepCooldown = true;

                    // Play footstep audio
                    this.audio.playFootstep(running);

                    // Reset cooldown after a fraction of a second to avoid double footsteps
                    setTimeout(() =>{
                        footstepCooldown = false;
                    }, 100);
                }
            }
            // If the player is running
            else
            {
                // Get rounded time of the animation
                const roundedTime = parseFloat(this.instance.animations.running.time.toFixed(2));
                // When one of the model's feet is touching the ground
                if((roundedTime >= 0.35 && roundedTime <= 0.45) || (roundedTime >= 0.75 && roundedTime <= 0.85))
                {
                    // Set cooldown to true
                    footstepCooldown = true;

                    // Play footstep audio
                    this.audio.playFootstep(running);

                    // Reset cooldown after a fraction of a second to avoid double footsteps
                    setTimeout(() => {
                        footstepCooldown = false;
                    }, 100);
                }
            }
        }
    }

    // Method propagated by the experience each tick event
    update()
    {
        // Get delta time
        delta = this.clock.getDelta();
        // Get the delta time
        const deltaTime = Math.min( 0.05, delta ) / STEPSPERFRAME;

        // Look for collisions in substeps to mitigate the risk of an object traversing another too quickly for detection
        for(let i = 0; i < STEPSPERFRAME; i ++)
        {
            // If the device is a desktop
            if(isMobile === false)
            {
                // Update movement keys
                this.#updateKeyStates(deltaTime);
            }
            // If the device is a mobile
            else if(isMobile === true)
            {
                // Update joystick values
                this.#updateJoystickValues(deltaTime);
            }

            // Update the player
            this.#updatePlayer(deltaTime);
        }

        // Update building
        this.building.update();

        // If the device is a desktop
        if(isMobile === false && this.pointer.mouseMove)
        {
            // Update the raycast
            this.#castRaycast(this.pointer.mouse);
        }

        // If the player is moving
        if(this.instance.moving === true)
        {
            // Manage footsteps SFX
            this.#manageFootsteps();

            // Reset variables
            this.instance.moving = false;

            // If the player is descending
            if(previousHeight > (this.instance.collider.start.y + 0.02))
            {
                // Set offset to look down slightly
                this.pointer.offset += 0.025;
                if(this.pointer.offset > 0.5) this.pointer.offset = 0.5;
            }
            // If the player isn't descending
            else this.pointer.offset = 0;
            
            // Save the player height
            previousHeight = this.instance.collider.start.y;
        }

        // Update camera
        this.camera.update();

        // Update the books collection minigame
        this.#updateBooksCollection();
        // If the player is in the Literature room
        if(this.instance.location === literatureArea)
        {
            // Manage piano notes
            this.#managePianoNotes();
        }
        // If the player is in the SDE room
        else if(this.instance.location === sdeArea)
        {
            // Manage the player proximity to the car
            this.#manageRaceCarProximity();
        }

        // Manage player location
        this.#managePlayerLocation();

        // Update the animation mixer
        this.instance.animations.mixer.update(delta);

        // If the device is an iOS mobile
        if(isMobile == true && this.mobileDetector.ios === true)
        {
            // If the user pressed the splashscreen button
            if(this.experience.STARTED == true)
            {
                // Update composer
                this.composer.update();
            }
        }
        // If the device isn't an iOS mobile
        else
        {
            // Update composer
            this.composer.update();
        }
    }
}