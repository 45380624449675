import './style.css';
import interactionsJSON from './interactions.json';
import Experience from './Experience/Experience';

// Experience class instance
let _experience;



// Start the experience
function startTheExperience()
{
    try
    {
        if(_experience !== undefined) throw "The Experience is already running"

        // Create the experience
        _experience = new Experience(document.querySelector('canvas.webgl'));
    }
    // Catch errors
    catch(e)
    {
        console.log(e);
    }
}
window.startTheExperience = startTheExperience;



// Function called to manage the user interactions
function manageInteractions(name)
{
    let interaction;

    // Find the corresponding data from the interactions JSON
    for(let i = 0; i < interactionsJSON.interactions.length; i++)
    {
        // If the interaction can be accessed by multiple elements
        if(typeof interactionsJSON.interactions[i].name != 'string')
        {
            // For each of the names in the array
            interactionsJSON.interactions[i].name.forEach(elem =>
            {
                // If the data from the JSON has the same name as the clicked object
                if(elem === name)
                {
                    // Get the element JSON data
                    interaction = interactionsJSON.interactions[i];
                }
            });

            // If the interaction was found, break the loop
            if(interaction !== undefined) break;
        }
        // If the interaction can be accessed by only one element
        else
        {
            // If the data from the JSON has the same name as the clicked object
            if(interactionsJSON.interactions[i].name === name)
            {
                // Get the JSON element data
                interaction = interactionsJSON.interactions[i];
                break;
            }
        }
    }

    // If the interaction should open an external link
    if(interaction.type === "link")
    {
        // Open new tab
        window.open(interaction.url, "_blank").focus();
    }
    // If the interaction should open the video modal
    else if(interaction.type === "video")
    {
        // Convert youtube link
        let regex = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;
        var match = regex.exec(interaction.url);

        // If the convertion was successful
        if (match && match[3].length == 11)
        {
            // Get video iframe
            const iframe = document.getElementById('video-iframe');
            // Set url
            iframe.src = '//www.youtube.com/embed/' + match[3];

            // Show video modal
            document.getElementsByClassName('video-modal')[0].classList.add("video-modal--active");

            // Pause audio
            _experience.audio.pauseOrResumeSoundtrack(false);
        }
        // If the convertion was unsuccessful
        else
        {
            console.log('Error while converting the Youtube link. Video could not be loaded.');
        }
    }
}


// Listen for clicks on the Facebook share button
document.getElementById('share__facebook').onclick = function()
{
    // Get url
    const url = document.getElementById('share__link').value;
    // Open new facebook tab
    window.open("https://www.facebook.com/sharer/sharer.php?u=" + url, "_blank").focus();
};

// Listen for clicks on the Twitter share button
document.getElementById('share__twitter').onclick = function()
{
    // Get url
    const url = document.getElementById('share__link').value;
    // Open new twitter tab
    window.open("https://twitter.com/intent/tweet?url=" + url, "_blank").focus();
};

// Listen for clicks on the Linkedin share button
document.getElementById('share__linkedin').onclick = function()
{
    // Get url
    const url = document.getElementById('share__link').value;
    // Open new linkedin tab
    window.open("https://www.linkedin.com/shareArticle?mini=true&url=" + url, "_blank").focus();
};


// Listen for user interactions
window.addEventListener('interface3DClickEvent', function(e)
{
    // Manage interaction
    manageInteractions(e.detail.clicked_elem);
});


// Listen for user interactions
window.addEventListener('loaded3DScene', function()
{
    // Wait a little more to finish configuring the player
    setTimeout(() =>
    {
        // Set loader image as the ready button
        const modal = document.getElementsByClassName('splashscreen__loader')[0];
        modal.classList.add('splashscreen__loader--ready');

        // Get the ready button
        const button = document.getElementsByClassName('splashscreen__loader')[0].firstChild;
        button.addEventListener('click', function()
        {
            // Set the experience as started
            _experience.STARTED = true;

            // Deactivate splash screen
            document.getElementById('splashscreen').classList.remove("splashscreen--active");
        });
    }, 2000);
});

startTheExperience();