import EventEmitter from './EventEmitter.js';

let keyDownHandler, keyUpHandler;

export default class Keys extends EventEmitter
{
    // Set constructor
    constructor()
    {
        // Extends the EventEmitter class
        super();

        // Set key states array
        this.keyStates = [];
        // Set the key listeners
        this.#setListeners();
    }

    // Method called by external classes to set the listeners
    #setListeners()
    {
        // Key down event handler
        keyDownHandler = (e) =>
        {
            // Set the key state
            this.keyStates[e.code] = true;
        };

        // Key up event handler
        keyUpHandler = (e) =>
        {
            // Reset the key state
            this.keyStates[e.code] = false;
        };

        // Listen for pressed keys
        document.addEventListener('keydown', keyDownHandler);
        // Listen for released keys
        document.addEventListener('keyup', keyUpHandler);
    }
}