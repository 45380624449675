export default class Interactive
{
    // Method called to set the interactable element
    setInteractable(object, distance)
    {
        // Save object and name
        this.object = object;
        this.name = this.object.name;
        this.collected = false;

        // Save the minimum interaction distance
        this.distance = distance;
    }

    // Method called to test if the player is inside the interaction range
    testProximity(playerPos)
    {
        // Get distance to player
        const dist = this.object.position.distanceTo(playerPos);
        
        // Return true if the player is inside the range
        if(dist <= this.distance) return true;
    }
}