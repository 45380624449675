export default class MobileDetector
{
    // Set constructor
    constructor()
    {
        this.detectMobile();
    }

    // Method called to verify if the user device is a mobile
    detectMobile()
    {
        this.isMobile = false;
        this.ios = false;
        this.webview = false;

        const ua = navigator.userAgent.toLowerCase();
        const queryString = window.location.search;

        // Verify if the device is a mobile
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua))
        {
            // Mobile
            this.isMobile = true;
        }
        else if (/mobile|android|ip(hone|od)|iemobile|blackberry|kindle|silk-accelerated|(hpw|web)os|opera m(obi|ini)/.test(ua))
        {
            // Tablet
            this.isMobile = true
        }

        // Verify if the device is an iOS mobile
        if(/ipad|ip(hone|od)/i.test(ua) || (ua.includes("mac") && "ontouchend" in document))
        {
            // iOS
            this.ios = true;
        }

        // Verify if the webpage is being open as a WebView
        if(queryString !== "" && queryString !== null && queryString !== undefined)
        {
            // Get the url parameters
            const urlParams = new URLSearchParams(queryString);
            // Search for the linkedin word
            if(/linkedin|fbclid|instagram|twitter/i.test(urlParams))
            {
                this.webview = true;
            }
        }
    }
}