import { TextureLoader, CubeTextureLoader, AudioLoader } from "three";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";

import EventEmitter from "./EventEmitter";

export default class Resources extends EventEmitter
{
    // Set constructor
    constructor(sources)
    {
        // Extends the EventEmitter class
        super();

        // Assets global path
        window.ASSETS_PATH = typeof window.ASSETS_PATH == 'undefined' ? 'public/' : window.ASSETS_PATH;

        // Get sources
        this.sources = sources;

        // Initialize variables
        this.items = {};
        this.toLoad = this.sources.length;
        this.loaded = 0;

        // Set loaders
        this.#setLoaders();
        // Start loading the resources
        this.#startLoading();
    }

    // Private method called to create and set up the loaders
    #setLoaders()
    {
        // Initialize instance
        this.loaders = {};

        // Create GLTF and DRACO loaders
        this.loaders.gltfLoader = new GLTFLoader();
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderConfig({ type: 'js' });
        dracoLoader.setDecoderPath('https://raw.githubusercontent.com/mrdoob/three.js/dev/examples/js/libs/draco/');
        this.loaders.gltfLoader.setDRACOLoader(dracoLoader);

        // Create texture loader
        this.loaders.textureLoader = new TextureLoader();
        this.loaders.textureLoader.setCrossOrigin("anonymous");

        // Create cube texture loader
        this.loaders.cubeTextureLoader = new CubeTextureLoader();

        // Create audio loader
        this.loaders.audioLoader = new AudioLoader();
    }

    // Private method called to start loading the resources
    #startLoading()
    {
        // Load each source
        for(const source of this.sources)
        {
            // If the source is a GLTF model
            if(source.type === 'gltfModel')
            {
                // Load model
                this.loaders.gltfLoader.load(
                    window.ASSETS_PATH + source.path,
                    (file) =>
                    {
                        // Resource finished loading
                        this.#sourceLoaded(source, file);
                    }
                );
            }
            // If the source is a texture
            else if(source.type === 'texture')
            {
                // Load texture
                this.loaders.textureLoader.load(
                    window.ASSETS_PATH + source.path,
                    (file) =>
                    {
                        // Resource finished loading
                        this.#sourceLoaded(source, file);
                    }
                );
            }
            // If the source is a cube texture
            else if(source.type === 'cubeTexture')
            {
                // Add the assets path to the start of each of the source paths
                let completeUrls = [];
                for(let i = 0; i < source.path.length; i++)
                {
                    let url = window.ASSETS_PATH + source.path[i];
                    completeUrls[i] = url;
                }

                // Load array of textures
                this.loaders.cubeTextureLoader.load(
                    completeUrls,
                    (file) =>
                    {
                        // Resource finished loading
                        this.#sourceLoaded(source, file);
                    }
                )
            }
            // If the source is an audio file
            else if(source.type === "audio")
            {
                // Load audio
                this.loaders.audioLoader.load(
                    window.ASSETS_PATH + source.path,
                    (file) =>
                    {
                        // Resource finished loading
                        this.#sourceLoaded(source, file);
                    }
                );
                
            }
        }
    }

    // Private method called to account the loaded resources
    #sourceLoaded(source, file)
    {
        // Save loaded resource
        this.items[source.name] = file;

        // Add to counter
        this.loaded++;

        // Get the loading percentage
        window.assetsLoadingPercentage = parseFloat((this.loaded * 100) / this.toLoad);

        // If all the resources have been loaded
        if(this.loaded === this.toLoad)
        {
            // Trigger event
            this.trigger('loadedResources');
            this.sources = null;
        }
    }
}