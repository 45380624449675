import * as THREE from "three";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";
import { GammaCorrectionShader } from "three/examples/jsm/shaders/GammaCorrectionShader.js";
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass.js';

import Experience from "./Experience";
import EventEmitter from './Utils/EventEmitter.js';

export default class Composer extends EventEmitter
{
    // Set constructor
    constructor()
    {
        // Extends the EventEmitter class
        super(); 

        // Get the experience instance
        this.experience = new Experience();
        // Get the needed classes from the experience
        this.mobileDetector = this.experience.mobileDetector;
        this.sizes = this.experience.sizes;
        this.scene = this.experience.scene;
        this.camera = this.experience.camera;
        this.renderer = this.experience.renderer;

        // Set composer
        this.setComposer();
    }

    // Method called to create and set up the composer
    setComposer()
    {
        // Create the render pass
        this.renderScene = new RenderPass( this.scene, this.camera.renderCamera );
        this.renderScene.renderToScreen = true;

        // Set rendering parameters
        let renderTargetParameters = { minFilter: THREE.LinearFilter, magFilter: THREE.LinearFilter, format: THREE.RGBAFormat, stencilBuffer: true };
        let renderTarget = new THREE.WebGLRenderTarget(this.sizes.width, this.sizes.height, renderTargetParameters);
        // Create the composer
        this.instance = new EffectComposer( this.renderer.instance, renderTarget );

        // Set size and pixel ratio
        this.instance.setSize(this.sizes.width, this.sizes.height);
        this.instance.setPixelRatio(this.sizes.pixelRatio);

        // Add render pass to the composer
        this.instance.addPass( this.renderScene );

        // If the device is a desktop
        if(this.mobileDetector.isMobile === false || this.mobileDetector.ios === false)
        {
            this.#setBloomPass();
            // Add bloom pass to the composer
            this.instance.addPass( this.bloomPass );
        }

        this.#setOutlinePass();
        // Add outline pass to the composer
        this.instance.addPass( this.outlinePass );

        this.#setGammaCorrectionPass();
        // Add gamma correction pass to the composer
        this.instance.addPass( this.gammaCorrectionPass );

        // If the device is a desktop
        if(this.experience.mobileDetector.isMobile === false)
        {
            this.#setFXAAPass();
            // Add FXAA pass to the composer
            this.instance.addPass( this.fxaaPass );
        }

        // If the device is a desktop
        if(this.mobileDetector.isMobile === false || this.mobileDetector.ios === false)
        {
            this.#setVignettePass();
            // Add vignette pass to the composer
            this.instance.addPass( this.vignettePass );
        }

        // Reset references
        this.scene = null;
        this.camera = null;
        this.renderer = null;
    }

    // Private method called to set up the Gamma Correction pass
    #setGammaCorrectionPass()
    {
        // Set gamma correction pass for color balancing
        this.gammaCorrectionPass = new ShaderPass( GammaCorrectionShader );
        this.gammaCorrectionPass.material.name = "GammaCorrectionPass";
    }

    // Private method called to set up the FXAA pass
    #setFXAAPass()
    {
        // Set the fxaa pass configurations for antialias
        this.fxaaPass = new ShaderPass( FXAAShader );
        this.fxaaPass.material.name = "FXAAPass";
        this.fxaaPass.uniforms['resolution'].value.set(1 / (this.sizes.width * this.sizes.pixelRatio), 1 / (this.sizes.height * this.sizes.pixelRatio));
    }

    // Private method called to set up the Bloom pass
    #setBloomPass()
    {
        // Set the world bloom pass
        this.bloomPass = new UnrealBloomPass( new THREE.Vector2( this.sizes.width, this.sizes.height ), 1.5, 0.4, 0.85 );
        this.bloomPass.threshold = 0;
        this.bloomPass.strength = 0.08;
        this.bloomPass.radius = 0.5;
    }

    // Private method called to set up the Outline pass
    #setOutlinePass()
    {
        // Set outline pass for the hovered stands
        this.outlinePass = new OutlinePass(new THREE.Vector2(this.sizes.width, this.sizes.height), this.scene, this.camera.renderCamera);
        this.outlinePass.renderToScreen = true;
        this.outlinePass.overlayMaterial.blending = THREE.AdditiveBlending;
        this.outlinePass.edgeStrength = 4;
        this.outlinePass.edgeGlow = 1;
        this.outlinePass.edgeThickness = 3;
        this.outlinePass.pulsePeriod = 2.5;
        this.outlinePass.visibleEdgeColor.set(0xffffff);
        this.outlinePass.hiddenEdgeColor.set(0x000000);
    }

    // Private method called to set up the Vignette pass
    #setVignettePass()
    {
        // Create vertex shader
        const vShader = `
            varying vec2 vUv;
            void main()
            {
                vUv = uv;
                gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
            }
        `;

        // Create fragment shader
        const fShader = `
            uniform float offset;
            uniform float darkness;
            uniform sampler2D tDiffuse;

            varying vec2 vUv;

            void main()
            {
                vec4 texel = texture2D( tDiffuse, vUv );
                vec2 uv = ( vUv - vec2( 0.5 ) ) * vec2( offset );
                gl_FragColor = vec4( mix( texel.rgb, vec3( 1.0 - darkness ), dot( uv, uv ) ), texel.a );
            }
        `;

        // Create vignette shader material
        const vignette = new THREE.ShaderMaterial(
        {
            uniforms:
            {
                tDiffuse: { type: 't', value: null },
                offset:   { type: 'f', value: 1.0 },
                darkness: { type: 'f', value: 1.0 }
            },
            vertexShader: vShader,
            fragmentShader: fShader
        });

        // Set the vignette pass configurations
        this.vignettePass = new ShaderPass( vignette );
        this.vignettePass.material.name = "VignettePass";
        this.vignettePass.material.uniforms['offset'].value = 1;
        this.vignettePass.material.uniforms['darkness'].value = 1.15;
    }

    // Method propagated by the experience when the screen is resized
    resize()
    {
        // Set new size and pixel ratio
        this.instance.setSize(this.sizes.width, this.sizes.height);
        this.instance.setPixelRatio(this.sizes.pixelRatio);

        // Update fxaa pass resolution
        if(this.fxaaPass) this.fxaaPass.uniforms['resolution'].value.set(1 / (this.sizes.width * this.sizes.pixelRatio), 1 / (this.sizes.height * this.sizes.pixelRatio));
    }

    // Method propagated by the experience each tick event
    update()
    {
        // Render
        this.instance.render();
    }
}