import * as THREE from "three";

import Experience from "./Experience.js";
import EventEmitter from './Utils/EventEmitter.js';

let obstacle;

export default class Camera extends EventEmitter
{
    // Set constructor
    constructor()
    {
        // Extends the EventEmitter class
        super();

        // Get the experience instance
        this.experience = new Experience();
        // Get the needed classes from the experience
        this.sizes = this.experience.sizes;
        this.scene = this.experience.scene;

        this.clock = new THREE.Clock();

        // Create raycast
        this.raycaster = new THREE.Raycaster();
        this.raycaster.far = 3;

        // Set camera instances
        this.setInstances();
    }

    // Method called to create and set up the cameras
    setInstances()
    {
        // Create first person perspective camera
        this.renderCamera = new THREE.PerspectiveCamera(50, this.sizes.width / this.sizes.height, 0.5, 2000);
        this.renderCamera.rotation.order = 'YXZ';
        this.renderCamera.position.set(-10.67, 2.55, 1.37);

        // Initialize current position and target vectors
        this.currentPosition = new THREE.Vector3();
        this.currentLookAt = new THREE.Vector3();

        // Add to scene
        this.scene.add(this.renderCamera);
        // Reset reference
        this.scene = null;
    }

    // Private method called to set up the camera collisions
    setCameraCollisions()
    {
        // Get the needed classes from the experience
        this.colliders = this.experience.colliders;
        this.player = this.experience.player;
        
        // Create array of collisions
        this.camCollisions = [];

        // Get each of the octree colliders
        this.colliders.octreeColliders.traverse(child =>
        {
            // Add to the array
            this.camCollisions.push(child);
        });

        // Get the player model and add to the array
        this.camCollisions.push(this.player.instance.model);
    }

    // Method propagated by the experience when the screen is resized
    resize()
    {
        // Update third person camera aspect
        this.renderCamera.aspect = this.sizes.width / this.sizes.height;
        this.renderCamera.updateProjectionMatrix();
    }

    // Private method used to calculate the ideal camera offset in relation to the character
    #calculateIdealOffset()
    {
        if(!this.pointer) this.pointer = this.experience.pointer;
        if(!this.player) this.player = this.experience.player;

        // Set base offset position
        const idealOffset = new THREE.Vector3(0, this.pointer.offset + 2, this.pointer.offset - 3);

        // Apply the character rotation and position to find the ideal camera position
        idealOffset.applyQuaternion(this.player.instance.model.quaternion);
        idealOffset.add(this.player.instance.model.position);

        // Return the calculated position
        return idealOffset;
    }

    // Private method used to calculate the ideal camera target in relation to the character
    #calculateIdealLookAt()
    {
        if(!this.pointer) this.pointer = this.experience.pointer;
        if(!this.player) this.player = this.experience.player;

        // Set base target position
        let idealLookAt = new THREE.Vector3(0, 1, 2);

        // If the pointer offset is less than 0, the player is trying to look up
        if(this.pointer.offset < 0)
        {
            // Set target to be higher
            idealLookAt.y += -(this.pointer.offset * 1.5);
        }

        // Apply the character rotation and position to find the ideal camera target
        idealLookAt.applyQuaternion(this.player.instance.model.quaternion);
        idealLookAt.add(this.player.instance.model.position);

        // Return the calculated target position
        return idealLookAt;
    }

    // Private method called to manage the raycast to detect the camera obstacles
    #manageRaycast()
    {
        // Cast raycast
        this.raycaster.setFromCamera(new THREE.Vector2(0, 0), this.renderCamera);
        const intersection = this.raycaster.intersectObjects(this.camCollisions, false);

        // If there is at least one intersection
        if(intersection.length > 0)
        {
            // Set default values
            obstacle = intersection[0];
            let nearestObstacle = 0;

            // For each of the intersections
            intersection.forEach(inter =>
            {
                // If the intersection isn't with the avatar mesh
                if(inter.object.name.includes('Mesh_Avatar') === false)
                {
                    // If the distance to the intersection is the nearest to the player
                    if(inter.distance > nearestObstacle)
                    {
                        // Save intersection
                        obstacle = inter;
                    }
                }
            });

            // Lerp camera to the intersection point
            this.renderCamera.position.lerp(obstacle.point, 0.9);
        }
    }

    // Method propagated by the experience each tick event
    update()
    {
        // Calculate camera offset and target
        const idealOffset = this.#calculateIdealOffset();
        const idealLookAt = this.#calculateIdealLookAt();

        // Set delta
        const t = 1.0 - Math.pow(0.001, this.clock.getDelta());

        // Lerp the camera to the calculated position and target
        this.currentPosition.lerp(idealOffset, t);
        this.currentLookAt.lerp(idealLookAt, t);

        // Set the camera position and target
        this.renderCamera.position.copy(this.currentPosition);
        this.renderCamera.lookAt(this.currentLookAt);

        // Manage camera collisions via raycast
        this.#manageRaycast();
    }
}