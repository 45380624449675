import { Vector2 } from "three";
import ClipboardJS from "clipboard";

import Experience from "../Experience.js";
import EventEmitter from './EventEmitter.js';

let touchHandler, mouseDownHandler, mouseMoveHandler, mouseUpHandler;

let pointerMoved = false, startPos = new Vector2(0, 0);
let isMobile;

export default class Pointer extends EventEmitter
{
    // Set constructor
    constructor()
    {
        // Extends the EventEmitter class
        super();

        // Get the experience instance
        this.experience = new Experience();
        // Get the needed classes from the experience
        this.canvas = this.experience.canvas;
        this.sizes = this.experience.sizes;
        this.resources = this.experience.resources;
        this.camera = this.experience.camera;
        this.audio = this.experience.audio;

        // Set drag offset
        this.offset = 0;

        // Listen for loaded resources event
        this.resources.on('loadedResources', () =>
        {
            // Get the player class from the experience
            this.player = this.experience.player;

            // Set pointer
            this.setPointer();

            // Remove reference
            this.resources.off('loadedResources');
            this.resources = null;
        });
    }

    // Method called to set up the pointer
    setPointer()
    {
        // Mouse position
        this.mouse = new Vector2(Infinity, Infinity);

        // Get the mobile detector class from the experience
        this.mobileDetector = this.experience.mobileDetector;
        // Get if the device is a mobile
        isMobile = this.mobileDetector.isMobile;
        // Remove reference
        this.mobileDetector = null;

        // If the device is a desktop
        if(isMobile === false)
        {
            // Mouse status
            this.mouseDown = false;
            this.mouseMove = false;
            // Set mouse move listener
            this.#setMouseListeners();
        }
        // If the device is a mobile
        else
        {
            // Set touch listeners
            this.#setTouchListeners();
        }

        // Set HUD buttons
        this.#setHUDButtons();
    }

    // Private method called to set up listeners related to the mouse movement
    #setMouseListeners()
    {
        // Mouse down event handler
        mouseDownHandler = (e) =>
        {
            // If the touch target is the canvas
            if(e.target.id === this.canvas.id)
            {
                // Set pointer as pressed
                this.mouseDown = true;

                // Get mouse position
                startPos.x = ((e.clientX - this.sizes.marginLeft) / this.sizes.width) * 2 - 1;
                startPos.y = -((e.clientY - this.sizes.marginTop) / this.sizes.height) * 2 + 1;
            }
        };

        // Mouse move event handler
        mouseMoveHandler = (e) =>
        {
            // If the touch target is the canvas
            if(e.target.id === this.canvas.id)
            {
                // Set mouse status as moving
                this.mouseMove = true;

                // Get mouse position
                this.mouse.x = (e.clientX / this.sizes.width) * 2 - 1;
                this.mouse.y = -(e.clientY / this.sizes.height) * 2 + 1;

                // If the pointer is pressed
                if(this.mouseDown === true)
                {
                    // Rotate camera as the mouse moves
                    this.player.instance.model.rotation.y -= e.movementX / 200;
                    this.camera.renderCamera.rotation.x -= e.movementY / 200;

                    // Add vertical movement to the camera
                    if((e.movementY < 0 && this.offset > -1) || (e.movementY > 0 && this.offset < 1.15))
                    {
                        // Add to the offset value
                        this.offset += e.movementY / 200;
                        // Limit between min and max values
                        this.offset = Math.min(Math.max(this.offset, -1), 1.15);
                    }
                }

                // If the user is hovering an object
                if(this.player.instance.hoveredElem !== null)
                {
                    // Change cursor to pointer
                    this.canvas.style.cursor = 'pointer';
                }
                // If the user isn't hovering an object
                else
                {
                    // Change cursor to default
                    this.canvas.style.cursor = 'unset';
                }
            }
        };

        mouseUpHandler = (e) =>
        {
            // If the touch target is the canvas
            if(e.target.id === this.canvas.id)
            {
                // Set pointer as released
                this.mouseDown = false;

                // Get mouse position
                this.mouse.x = ((e.clientX - this.sizes.marginLeft) / this.sizes.width) * 2 - 1;
                this.mouse.y = -((e.clientY - this.sizes.marginTop) / this.sizes.height) * 2 + 1;

                // If the scene is loaded and the mouse click hasn't moved
                if(this.experience.SCENE_LOADED && this.equals(startPos, this.mouse, 0.02) === true)
                {
                    // Update camera
                    this.camera.renderCamera.updateMatrixWorld();

                    // Trigger touch event
                    this.trigger('pointerTouch');

                    // If the user is hovering an object during the click
                    if(this.player.instance.hoveredElem !== null)
                    {
                        // Data to be sent
                        let data = { 'clicked_elem': this.player.instance.hoveredElem };

                        // Trigger event warning that an object was clicked, sending the info acquired
                        const clickEvent = new CustomEvent( 'interface3DClickEvent', { detail: data } );
                        window.dispatchEvent(clickEvent);

                        // Reset variable
                        this.player.instance.hoveredElem = null;
                    }
                }
            }
        };

        // Listen for mouse clicks
        document.addEventListener('mousedown', mouseDownHandler);
        // Listen for mouse movements
        document.addEventListener('mousemove', mouseMoveHandler);
        // Listen for mouse releases
        document.addEventListener('mouseup', mouseUpHandler);
    }

    // Private method called to set up the touch listeners
    #setTouchListeners()
    {
        // Touch event handler
        touchHandler = (e) =>
        {
            // If the touch target is the canvas
            if(e.target.id === this.canvas.id)
            {
                // If the touch started
                if(e.type === "touchstart")
                {
                    // Get touch position
                    this.mouse.x = ((e.touches[0].clientX - this.sizes.marginLeft) / this.sizes.width) * 2 - 1;
                    this.mouse.y = -((e.touches[0].clientY  - this.sizes.marginTop) / this.sizes.height) * 2 + 1;
                }
                // If the touch moved
                else if(e.type === "touchmove")
                {
                    // Set verifier to true
                    pointerMoved = true;
                    
                    // Get current position
                    const currentMousePos = new Vector2(((e.touches[0].clientX - this.sizes.marginLeft) / this.sizes.width) * 2 - 1, -((e.touches[0].clientY  - this.sizes.marginTop) / this.sizes.height) * 2 + 1);

                    // Get differencial between the start position and the current position
                    const dif = new Vector2(0, 0);
                    dif.copy(this.mouse);
                    dif.sub(currentMousePos);

                    // Rotate camera as the touch moves
                    this.player.instance.model.rotation.y += dif.x * 2.5;
                    this.camera.renderCamera.rotation.x += dif.x * 2.5;

                    // Add to the offset value
                    this.offset += dif.y * 2;
                    // Limit between min and max values
                    this.offset = Math.min(Math.max(this.offset, -1), 1.15);

                    // Get touch position
                    this.mouse.x = ((e.touches[0].clientX - this.sizes.marginLeft) / this.sizes.width) * 2 - 1;
                    this.mouse.y = -((e.touches[0].clientY  - this.sizes.marginTop) / this.sizes.height) * 2 + 1;
                }
                // If the touch ended
                else if(e.type === "touchend")
                {
                    // If the touch didn't move
                    if(pointerMoved === false)
                    {
                        // Trigger touch event
                        this.trigger('pointerTouch');
                    }
                    // If the touch moved
                    else
                    {
                        // Reset variable
                        pointerMoved = false;
                    }
                }
            }
        };

        // Listen for touches starting
        document.addEventListener('touchstart', touchHandler);
        // Listen for touches moving
        document.addEventListener('touchmove', touchHandler);
        // Listen for touches ending
        document.addEventListener('touchend', touchHandler);
    }

    // Private method to set up the HUD buttons interactions
    #setHUDButtons()
    {
        // Get buttons
        const audioButton = document.getElementById('button--audio');
        // Listen for clicks
        audioButton.onclick = () =>
        {
            // Update icon
            if(this.audio.playing === true) audioButton.classList.add('button--audio--disabled');
            else audioButton.classList.remove('button--audio--disabled');

            // Set the audio button configuration
            this.audio.buttonConfig = !this.audio.buttonConfig;
            // Play or pause the soundtrack
            this.audio.pauseOrResumeSoundtrack(this.audio.buttonConfig);
        };

        // Get map button
        const mapButton = document.getElementById('button--map');
        // Listen for clicks
        mapButton.onclick = () =>
        {
            // Set default location
            if(this.player.instance.location === null) this.player.instance.location = 'institutional';

            // Get player location
            let location = this.player.instance.location;
            let tag = this.player.instance.location;

            // If the location is a tag variant, change the location value
            if(location === 'start') location = 'institutional';
            else if(location === 'outside') location = 'sde';

            // Get the map modal
            const mapModal = document.getElementById('map-modal--' + location);
            let mapModalTag;

            // If the player is on a location that needs tag variants
            if(tag !== location)
            {
                // For each of the map modal child nodes
                for(let i = 0; i < mapModal.childNodes.length; i++)
                {
                    // Find the tag title
                    if(mapModal.childNodes[i].classList.contains("map-modal__title"))
                    {
                        // Get the map tag
                        mapModalTag =  mapModal.childNodes[i];

                        // Change the location tag for the variant tag
                        mapModalTag.classList.remove('informative-tag--' + location)
                        mapModalTag.classList.add('informative-tag--' + tag)
                        break;
                    }        
                }
            }

            // Show the map modal from the current location
            mapModal.classList.add('map-modal--active');

            // Get exit button
            const exitButton = document.getElementById('exit-button--' + location);
            // Deactivate map modal when the exit button is clicked
            exitButton.onclick = () =>
            {
                // Remove active animation
                mapModal.classList.remove('map-modal--active');

                // If the player is on a location that needs tag variants
                if(tag !== location)
                {
                    // Wait for the fade out animation
                    setTimeout(() =>
                    {
                        // Change the variant tag back to the location tag
                        mapModalTag.classList.remove('informative-tag--' + tag);
                        mapModalTag.classList.add('informative-tag--' + location);
                    }, 300);
                }
            }

            // Get the link text
            const linkButton = document.getElementById('map-modal__link--' + location);
            linkButton.onclick = () =>
            {
                let link = 'https://' + linkButton.innerHTML;
                if(location === "literature") link = "https://ftd.com.br/produto-solucao-literatura/";
                if(location === "sde") link = "https://ftd.com.br/produto-solucao-ftd-sistema-de-ensino/";

                // Open the website showed in the text
                window.open(link).focus();
            }
        };

        // Get share button
        const shareButton = document.getElementById('button--share');
        // Listen for clicks
        shareButton.onclick = () =>
        {
            // Show the share modal
            document.getElementById('share-modal').classList.add('share-modal--active');

            // Get exit button
            const exitButton = document.getElementById('exit-button--share');
            // Deactivate share modal when the exit button is clicked
            exitButton.onclick = () =>
            {
                // Remove active animation
                document.getElementById('share-modal').classList.remove('share-modal--active');
            }
        };
        // Set copy link button
        const copyButton = document.getElementsByClassName('share-modal__copy-link')[0];
        new ClipboardJS(copyButton);

        // Get help button
        const helpButton = document.getElementById('button--info');
        // Listen for clicks
        helpButton.onclick = () =>
        {
            // Show the controls modal
            document.getElementById('controls-modal').classList.add('splashscreen--active');

            // Get exit button
            const exitButton = document.getElementById('exit-button--controls');
            // Deactivate controls modal when the exit button is clicked
            exitButton.onclick = () =>
            {
                // Remove active animation
                document.getElementById('controls-modal').classList.remove('splashscreen--active');
            }
        };

        // Get exit button
        const videoModalExit = document.getElementById('exit-button__video-modal');
        // Listen for clicks
        videoModalExit.onclick = () =>
        {
            // Deactivate video modal after the exit button is clicked
            document.getElementById('video-modal').classList.remove("video-modal--active");
            document.getElementById('video-iframe').src = '';

            // Resume audio if possible
            if(this.audio.buttonConfig === true) this.audio.pauseOrResumeSoundtrack(true);
        }
    }

    // Method called by the player when the last book is collected
    manageGiftModal()
    {
        // Show the gift modal
        document.getElementById('gift-modal').classList.add('splashscreen--active');

        // Get submit button
        const submitButton = document.getElementsByClassName('lp-form-button')[0];
        // If there is a submit button
        if(submitButton)
        {
            // Show message when the submit button is clicked
            submitButton.onclick = () =>
            {
                // Disable form and enable message
                document.getElementById('gift__form').style.display = "none";
                document.getElementsByClassName('email_success')[0].style.display = "block";
            }
        }

        // Get exit button
        const exitButton = document.getElementById('exit-button--gift');
        // Deactivate controls modal when the exit button is clicked
        exitButton.onclick = () =>
        {
            // Remove active animation
            document.getElementById('gift-modal').classList.remove('splashscreen--active');
        }
    }

    // Method called to compare vectors and get if they are the same
    equals(firstValue, secValue, tolerance)
    {
        // If the tolerance factor isn't defined
        if(tolerance === undefined)
        {
            // Return literal sameness
            return ((firstValue.x === secValue.x) && (firstValue.y === secValue.y));
        }
        // If the tolerance factor is defined
        else
        {
            // Return if the vectors are the same within the tolerance
            return ((Math.abs(firstValue.x - secValue.x) < tolerance) && (Math.abs(firstValue.y - secValue.y) < tolerance));
        }
    }
}