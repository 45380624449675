import * as THREE from "three";
import gsap from 'gsap';

import Experience from "../Experience.js";
import EventEmitter from "../Utils/EventEmitter.js";
import Interactive from "./Interactive.js";

let dissolvedLiteratureForceField = false, dissolvedSDEForceField = false, isMobile = false;

export default class Building extends EventEmitter
{
    // Set constructor
    constructor()
    {
        super();

        // Get the experience instance
        this.experience = new Experience();
        // Get the needed classes from the experience
        this.scene = this.experience.scene;
        this.resources = this.experience.resources;
        this.environment = this.experience.environment;

        // Create instance
        this.instance = {};

        // Create clock
        this.clock = new THREE.Clock();

        // Listen to when the resources are loaded
        this.resources.on('loadedResources', () =>
        {
            // Get the needed classes from the experience
            isMobile = this.experience.mobileDetector.isMobile;
            this.colliders = this.experience.colliders;
            this.composer = this.experience.composer;

            // Set up the scene
            this.#setupScene();
            this.environment.setEnvironmentBackground();

            // Reset references
            this.resources.off('loadedResources');
            this.resources = null;
            this.environment = null;
        });
    }

    // Private method called to create and set up the scenary
    #setupScene()
    {
        // Set the needed variables
        this.instance.elevator = [];
        this.instance.forceFields = [[], []];
        this.instance.pianoNotes = [];
        this.instance.movingScreens = [];
        this.instance.rocket = null;
        this.instance.raceCar = null;
        this.instance.collectableBooks = [];
        this.instance.positionedBooks = [];
        this.instance.hasGlow = [];
        this.instance.interactables = [];
        this.instance.floatingPlants = [];
        this.instance.floatingVariants = [];

        const gltf = this.resources.items["environmentModel"];
        // Get model
        this.instance.model = gltf.scene;

        // Create object
        this.animations = {};
        // Set animation mixer
        this.animations.mixer = new THREE.AnimationMixer(this.instance.model);

        // Get animations
        this.animations.rocketAnim = this.animations.mixer.clipAction(gltf.animations[98]);
        this.animations.ionicAnims = [this.animations.mixer.clipAction(gltf.animations[137]), this.animations.mixer.clipAction(gltf.animations[138]), this.animations.mixer.clipAction(gltf.animations[139])];
        this.animations.ionicAnims.forEach((anim) => { anim.play() });

        // Go through all the model's children
        this.instance.model.traverse((child) =>
        {
            // If the child is a force field
            if(child.name.includes('ForceField'))
            {
                let section, type;

                // Get the room to which the force field is linked
                if(child.name.includes('Literatura')) section = 0;
                else if(child.name.includes('SDE')) section = 1;

                // Get the type of the child
                if(child.name.includes('Mesh')) type = 0;
                else if(child.name.includes('Collider')) type = 1;

                // Add to the force field array accordingly to their linked room and type
                this.instance.forceFields[section][type] = child;
            }

            // If the child is a collider
            if(child.name.includes('Collider'))
            {
                // Add to the colliders array
                this.colliders.addCollider(child.clone());
                child.material.visible = false;
            }
            // If the child should have a glow effect
            else if(child.name.includes('HasGlow'))
            {
                // Add to the glowy elements array
                this.instance.hasGlow.push(child);
            }

            // If the child is a part of the elevator
            if(child.name.includes('Mesh_Elevator'))
            {
                // Create new interactable element
                const elevator = new Interactive();
                elevator.setInteractable(child, 1);

                // Add to the elevator group
                this.instance.elevator.push(elevator);
            }
            // If the child is a part of the force fields
            else if(child.name.includes('ForceField'))
            {
                // Add to the force fields group
                this.instance.forceFields.push(child);
            }
            // If the child is a part of the piano notes stairs
            else if(child.name.includes('Piano_Note'))
            {
                // Add to the piano notes array
                this.instance.pianoNotes[child.name.split('0')[1] - 1] = child;
            }
            // If the child is the moving screens at the "Literature" and "SDE" sections
            else if(child.name.includes('BigScreen'))
            {
                // Get the type of the screen
                let id = 0;
                if(child.name.includes('SDE')) id = 1;

                // Get moving screen
                this.instance.movingScreens[id] = child;
                this.instance.movingScreens[id].material = child.material.clone();
            }
            // If the child is a book related element
            else if(child.name.includes('Mesh_Book'))
            {
                // Get the element id
                const id = child.name.split('_')[2].split('0')[1];

                // If the book is a collectable book
                if(child.name.includes('Interactive'))
                {
                    // Create new interactable element
                    const book = new Interactive();
                    book.setInteractable(child, 2);

                    // Add to the collectables array
                    this.instance.collectableBooks[id - 1] = book;
                }
                // If the book is a placeholder for the collected books
                else if(child.name.includes('Positioned'))
                {
                    // Add to the placeholders array
                    this.instance.positionedBooks[id - 1] = child;
                }
                // If the element is a video screen about the collected books
                else if(child.name.includes('BookTrailers'))
                {
                    // Create new interactable element
                    const video = new Interactive();
                    video.setInteractable(child, 6);

                    // Add to the interactables array
                    this.instance.interactables.push(video);
                }
            }

            // If the child is an interactive screen
            if(child.name.includes('Button') || child.name.includes('Video') || child.name.includes('Tablet'))
            {
                // Create new interactable element
                const interactive = new Interactive();
                interactive.setInteractable(child, 6);
                
                // Add to the interactables array
                this.instance.interactables.push(interactive);

                // Only show the front face of the buttons
                if(child.name.includes('Button')) child.material.side = THREE.FrontSide;
            }

            // If the child is a floating plant vase
            if(child.name.includes('CanteirosVoadores'))
            {
                // If the child isn't the plant per say
                if(child.name.includes('Plantas') === false)
                {
                    // Get child id
                    const id = child.name.split('_')[2].split('0')[1];

                    // Add to floating plant vases array
                    this.instance.floatingPlants[id - 1] = child;

                    // Set floating variant to the plant vase
                    let variant = Math.random() * (3 - 0 + 1) + 0;
                    this.instance.floatingVariants[id - 1] = variant;
                }
            }

            // If the child is the rocket
            if(child.name.includes('Rocket'))
            {
                // Get rocket and set to invisible for now
                this.instance.rocket = child;
                this.instance.rocket.visible = false;
            }
            // If the child is the race car
            if(child.name.includes('RaceCar'))
            {
                // Create new interactable element
                const car = new Interactive();
                car.setInteractable(child, 8);

                // Set race car
                this.instance.raceCar = car;
            }
        });

        // Add outline effect to the glowy elements
        this.composer.outlinePass.selectedObjects = this.instance.hasGlow;
        // Generate the octree colliders
        this.colliders.generateOctreeColliders();

        // Add to the scene
        this.scene.add(this.instance.model);
        this.scene = null;

        // Update materials
        this.#updateMaterials(this.instance.model);

        // Trigger scene as loaded
        this.trigger('loaded3DScene');
    }

    // Private method called to update the model materials
    #updateMaterials(model)
    {
        // Go through all the model's children
        model.traverse((child) =>
        {
            // If child is a mesh object and their material is a standard material
            if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
            {
                // Receive shadows
                child.receiveShadow = true;
 
                // Set children material encoding
                child.material.encoding = THREE.sRGBEncoding;
                if(child.material.map != null) child.material.map.encoding = THREE.sRGBEncoding;
                child.material.needsUpdate = true;
            }
        });
    }

    // Method called to start or stop an animation clip
    startStopClip(clip, bool)
    {
        // If the animation must be started
        if(bool === true)
        {
            // Play clip
            clip.reset()
            .setEffectiveWeight(1)
            .play();
        }
        // If the animation must be stopped
        else
        {
            // Stop clip
            clip.stop();
        }
    }

    // Method called to animate the elevator
    useElevator()
    {
        // Get the elevator target
        let target;
        if(this.instance.elevator[0].object.position.y > 0) target = -4.8;
        else target = 3.44;

        // Get the player class from the experience
        this.player = this.experience.player;

        // For each of the elevator parts
        this.instance.elevator.forEach(elevator =>
        {
            // Tween the elevator position
            gsap.to(elevator.object.position, { y: target, duration: 5, onUpdate: () =>
            {
                // Move the player as well
                const col = this.player.instance.collider.start;
                this.player.instance.collider.start.set(col.x, elevator.object.position.y + 0.75, col.z);
                this.player.instance.collider.end.set(col.x, elevator.object.position.y + 1.75, col.z);
            } });
        });
    }

    // Method called to dissolve a force field
    dissolveForceField(id)
    {
        // If the player is on the Literature room and the force field hasn't been dissolved yet
        if(id === 0 && dissolvedLiteratureForceField === false)
        {
            dissolvedLiteratureForceField = true;

            // Remove collider and deactivate mesh
            this.colliders.removeCollider(this.instance.forceFields[0][1].name);
            this.instance.forceFields[0][0].visible = false;

            // Update camera collisions
            if(!this.camera) this.camera = this.experience.camera;
            this.camera.setCameraCollisions();

            // Play the spaceship effect only once
            setTimeout(() => {
                if(this.experience.audio.buttonConfig === true) this.experience.audio.audios.spaceshipEffect.play();
            }, 1500);
        }
        // If the player is on the SDE room and the force field hasn't been dissolved yet
        else if(id === 1 && dissolvedSDEForceField === false)
        {
            dissolvedSDEForceField = true;

            // Remove collider and deactivate mesh
            this.colliders.removeCollider(this.instance.forceFields[1][1].name);
            this.instance.forceFields[1][0].visible = false;

            // Update camera collisions
            if(!this.camera) this.camera = this.experience.camera;
            this.camera.setCameraCollisions();
        }
    }

    // Method propagated by the experience each tick event
    update()
    {
        // Update the animation mixer
        this.animations.mixer.update(this.clock.getDelta());

        // Get elapsed time multiplied by two
        const time = this.clock.getElapsedTime();

        // For each of the moving screens
        for(let i = 0; i < this.instance.movingScreens.length; i++)
        {
            // Set the moving texture animation
            let offset = this.instance.movingScreens[i].material.map.offset.x - 0.0008;
            // Reset texture offset seamlessly
            if(i === 0 && offset < -0.255) offset = 0;
            else if(i === 1 && offset < -0.25) offset = 0;
            // Set texture offset
            this.instance.movingScreens[i].material.map.offset = new THREE.Vector3(offset, 0);
        }

        // For each of the active books
        this.instance.collectableBooks.forEach(book =>
        {
            // If the book wasn't collected yet
            if(book.collected === false)
            {
                // Animate floating effect
                book.object.position.y += Math.cos(time * 2) * 0.008;
            }
        });

        // For each of the floating plant vases
        for(let i = 0; i < this.instance.floatingPlants.length; i++)
        {
            // Animate floating effect
            this.instance.floatingPlants[i].position.y -=  Math.cos(time + this.instance.floatingVariants[i]) * 0.01;
        }
    }
}