import * as THREE from "three";

import Experience from "./Experience";

export default class Renderer
{
    // Set constructor
    constructor()
    {
        // Get the experience instance
        this.experience = new Experience();
        // Get the needed classes from the experience
        this.canvas = this.experience.canvas;
        this.sizes = this.experience.sizes;
        this.scene = this.experience.scene;
        this.camera = this.experience.camera;

        // Set renderer
        this.setRenderer();
    }

    // Method called to create and set up the renderer
    setRenderer()
    {
        // Create renderer instance
        this.instance = new THREE.WebGLRenderer({
            canvas: this.canvas,
            powerPreference: "high-performance",
            logarithmicDepthBuffer: true
        });

        // Set size, color and pixel ratio
        this.instance.setSize(this.sizes.width, this.sizes.height);
        this.instance.setClearColor( 0x000000, 0 );
        this.instance.setPixelRatio(this.sizes.pixelRatio);

        // Set shadows and lights
        this.instance.shadowMap.enabled = false;

        // Reset reference
        this.canvas = null;
    }

    // Method propagated by the experience when the screen is resized
    resize()
    {
        // Set new size and pixel ratio
        this.instance.setSize(this.sizes.width, this.sizes.height);
        this.instance.setPixelRatio(this.sizes.pixelRatio);
    }

    // Method propagated by the experience each tick event
    update()
    {
        // Render
        this.instance.render(this.scene, this.camera.renderCamera);
    }
}