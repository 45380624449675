import EventEmitter from './EventEmitter.js';

let resizeHandler;

export default class Sizes extends EventEmitter
{
    // Set constructor
    constructor(canvas)
    {
        // Extends the EventEmitter class
        super();

        // Get canvas sizes and pixel ratio
        this.width = window.innerWidth;
        this.height = window.innerHeight;
        this.pixelRatio = Math.min(window.devicePixelRatio, 2);

        // Get canvas margin
        let rect = canvas.getBoundingClientRect();
        this.marginLeft = rect.left;
        this.marginTop = rect.top;

        // Resize event handler
        resizeHandler = () =>
        {
            // Get new canvas sizes and pixel ratio
            this.width =  window.innerWidth;
            this.height =  window.innerHeight;
            this.pixelRatio = Math.min(window.devicePixelRatio, 2);

            // Get canvas margin
            let rect = canvas.getBoundingClientRect();
            this.marginLeft = rect.left;
            this.marginTop = rect.top;

            // Trigger resize event
            this.trigger('resize');
        };

        // Listen for the resize event
        window.addEventListener('resize', resizeHandler);
    }
}