import { Scene } from "three";
import ClipboardJS from "clipboard";

import Sizes from "./Utils/Sizes.js";
import Time from "./Utils/Time.js";
import MobileDetector from "./Utils/MobileDetector.js";
import Disposer from "./Utils/Disposer.js";
import Keys from "./Utils/Keys.js";
import Joystick from "./Utils/Joystick.js";
import Resources from "./Utils/Resources.js";
import Camera from "./Camera.js";
import Audio from "./Audio.js";
import Renderer from "./Renderer.js";
import Composer from "./Composer.js";
import Pointer from "./Utils/Pointer.js";
import Environment from "./World/Environment.js";
import Building from "./World/Building.js";
import Colliders from "./World/Colliders.js";
import Player from "./World/Player.js";

import sources from "./sources.js";

// Global instance
let instance = null;
// Time variables
let delta = 0;
const interval = 1 / 30;

// FPS test
//(function () { var script = document.createElement('script'); script.onload = function () { var stats = new Stats(); document.body.appendChild(stats.dom); requestAnimationFrame(function loop() { stats.update(); requestAnimationFrame(loop) }); }; script.src = '//mrdoob.github.io/stats.js/build/stats.min.js'; document.head.appendChild(script); })();

export default class Experience
{
    // Set constructor
    constructor(canvas)
    {
        // Return the existing instance
        if(instance) return instance;
        // Create a new instance if needed
        instance = this;

        // Get canvas
        this.canvas = canvas;

        // Scene loaded verifier
        this.SCENE_LOADED = false;
        this.STARTED = false;

        // Verify mobile
        this.mobileDetector = new MobileDetector();

        // If the device is is being open as a Linkedin WebView and the user device is an iOS
        if(this.mobileDetector.isMobile == true && this.mobileDetector.ios == true && this.mobileDetector.webview == true)
        {
            // Show webview blocker modal
            document.getElementById('webview-block-modal').classList.add('splashscreen--active');

            // Set up the webview blocker copy button
            const copyButton = document.getElementById('webview-modal__copy-link');
            new ClipboardJS(copyButton);

            // Set "success" button
            copyButton.onclick = function()
            {
                copyButton.classList.add('copied-link');
            };
        }
        // If the device isn't being open as a Linkedin WebView
        else
        {
            // Set persistens utils
            this.#setPersistentUtils();
            // Set utils that can be temporarily destroyed
            this.#setUtils();
        }
    }

    // Method called to set up the persistent classes
    #setPersistentUtils()
    {
        // Setup utils
        this.sizes = new Sizes(this.canvas);
        this.time = new Time();
        this.disposer = new Disposer();
        // Load resources
        this.resources = new Resources(sources);

        // Listen for resize event
        this.sizes.on('resize', () =>
        {
            // Call local resize method
            this.resize();
        });

        // Time tick event
        this.time.on('tick', () =>
        {
            // Update delta
            delta += this.time.delta / 1000;
            // If the delta time surpassed the interval needed
            if(delta > interval)
            {
                // If the scene is loaded
                if(this.SCENE_LOADED === true)
                {
                    // Call local update method
                    this.update();
                }
                
                // Reset delta
                delta = delta % interval;
            }
        });
    }

    // Method called to set up the needed classes
    #setUtils()
    {
        // If the device is a desktop, setup keys
        if(this.mobileDetector.isMobile === false) this.keys = new Keys();
        // If the device is a mobile, setup joysticks
        else if(this.mobileDetector.isMobile === true) this.joystick = new Joystick();

        // Create scene
        this.scene = new Scene();
        // Setup environment
        this.environment = new Environment();
        this.building = new Building();

        // Setup camera and rendering
        this.camera = new Camera();
        this.audio = new Audio();
        this.renderer = new Renderer();
        this.composer = new Composer();
        this.pointer = new Pointer();

        // Setup world colliders
        this.colliders = new Colliders();
        // Setup player
        this.player = new Player();

        // Set listeners
        this.#setListeners();
    }

    // Method called to set the listeners
    #setListeners()
    {
        // Listen for loaded resources event
        this.building.on('loaded3DScene', () =>
        {
            // Set the player
            this.player.setPlayer();

            // Set verification variable to true
            this.SCENE_LOADED = true;

            // Trigger event once to signal that all the content has been loaded
            const loaded3DSceneEvent = new CustomEvent( 'loaded3DScene' );
            window.dispatchEvent(loaded3DSceneEvent);

            // Remove listener
            this.building.off('loaded3DScene');
        });
    }

    // Method called when the screen is resized
    resize()
    {
        // Propagate to the classes that need to be updated
        this.camera.resize();
        this.renderer.resize();
        this.composer.resize();
    }

    // Method called by the tick event that propagates it to the classes that need updates every tick
    update()
    {
        // Update the player and the building
        this.player.update();
    }
}