import * as THREE from "three";

import Experience from "../Experience.js";

export default class Environment
{
    // Method called to set up the environment background
    setEnvironmentBackground()
    {
        // Get the experience instance
        this.experience = new Experience();
        // Get the needed classes from the experience
        this.scene = this.experience.scene;
        this.resources = this.experience.resources;

        // If there isn't a background
        if(!this.envBackground)
        {
            // Create environment background
            this.envBackground = this.resources.items.environmentBackground;
            this.envBackground.encoding = THREE.sRGBEncoding;
        }
        
        // Set scene background
        this.scene.background = this.envBackground;

        // Reset references
        this.resources = null;
        this.scene = null;
        this.experience = null;
    }
}