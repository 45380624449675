export default [
    {
        name: 'environmentBackground',
        type: 'cubeTexture',
        path:
        [
            'environment/px.png',
            'environment/nx.png',
            'environment/py.png',
            'environment/ny.png',
            'environment/pz.png',
            'environment/nz.png'
        ]
    },
    {
        name: 'environmentModel',
        type: 'gltfModel',
        path: 'models/environment/FTD_Environment_Adjustments_Final.gltf'
    },
    {
        name: 'npc',
        type: 'gltfModel',
        path: 'models/npc/npc.gltf'
    },
    {
        name: 'aim',
        type: 'texture',
        path: 'interface/images/first-person-aim.png'
    },
    {
        name: 'soundtrack_institutional',
        type: 'audio',
        path: 'audio/soundtracks/trilha_area_institucional.mp3'
    },
    {
        name: 'soundtrack_ionic',
        type: 'audio',
        path: 'audio/soundtracks/trilha_area_ionica.mp3'
    },
    {
        name: 'soundtrack_literature',
        type: 'audio',
        path: 'audio/soundtracks/trilha_area_literatura.mp3'
    },
    {
        name: 'soundtrack_sde',
        type: 'audio',
        path: 'audio/soundtracks/trilha_area_sistema_ensino.mp3'
    },
    {
        name: 'soundtrack_external',
        type: 'audio',
        path: 'audio/soundtracks/sfx_ambiencia_vento_areas_externas.mp3'
    }
]