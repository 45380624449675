import { Vector3 } from "three";
import * as nipplejs from "nipplejs";

export default class Joystick
{
    // Set constructor
    constructor()
    {
        // Create the movement joystick
        this.#createMovementJoystick();
    }

    // Private method called to create and set up the movement joystick
    #createMovementJoystick()
    {
        // Set instance
        this.movementJoystick = {};

        // Set base values
        this.movementJoystick.forwardValue = 0;
        this.movementJoystick.backwardValue = 0;
        this.movementJoystick.rightValue = 0;
        this.movementJoystick.leftValue = 0;
        this.movementJoystick.movementVector = new Vector3();
        this.movementJoystick.container = document.getElementById('joystick-wrapper-movement');

        // Set joystick options
        const options = {
            zone: this.movementJoystick.container,
            size: 100,
            restOpacity: 1,
            color: '#000000',
            multitouch: true,
            maxNumberOfNipples: 1,
            mode: 'static',
            restJoystick: true,
            shape: 'square',
            position: { bottom: '80px', right: '80px' },
            dynamicPage: true
        };

        // Create joystick with the selected options
        this.movementManager = nipplejs.create(options);

        // On joystick move
        this.movementManager['0'].on('move', (evt, data) =>
        {
            // Get movement values
            const forward = data.vector.y;
            const turn = data.vector.x;
            this.movementJoystick.force = data.force;
    
            // Set inputs
            this.#joystickInputs(this.movementJoystick, forward, turn);
        });

        // On joystick stop moving
        this.movementManager['0'].on('end', (evt) =>
        {
            // Reset variables
            this.movementJoystick.backwardValue = 0;
            this.movementJoystick.forwardValue = 0;
            this.movementJoystick.leftValue = 0;
            this.movementJoystick.rightValue = 0;
        });

        // Display interface
        document.getElementById('player-mobile-interface').style.display = '';

        // Change joystick z-index
        this.movementJoystick.container.firstChild.style.zIndex = "1";
        // Stylize joystick back
        this.movementJoystick.container.firstChild.firstChild.style.background = '#0000004d';
        this.movementJoystick.container.firstChild.firstChild.style.border = '2px solid white';
        this.movementJoystick.container.firstChild.firstChild.style.borderRadius = '36%';
        this.movementJoystick.container.firstChild.firstChild.style.opacity = 'unset';
        // Stylize joystick front
        this.movementJoystick.container.firstChild.lastChild.style.background = '#ffffffbf';
        this.movementJoystick.container.firstChild.lastChild.style.border = '2px solid white';
        this.movementJoystick.container.firstChild.lastChild.style.opacity = 'unset';
    }

    // Private method called to set the joystick inputs
    #joystickInputs(joystick, forward, turn)
    {
        // If the joystick is moving forwards
        if(forward > 0)
        {
            joystick.forwardValue = Math.abs(forward);
            joystick.backwardValue = 0;
        }
        // If the joystick is moving backwards
        else if(forward < 0)
        {
            joystick.forwardValue = 0;
            joystick.backwardValue = Math.abs(forward);
        }

        // If the joystick is moving to the right
        if(turn > 0)
        {
            joystick.leftValue = 0;
            joystick.rightValue = Math.abs(turn);
        }
        // If the joystick is moving to the left
        else if(turn < 0)
        {
            joystick.leftValue = Math.abs(turn);
            joystick.rightValue = 0;
        }
    }
}