import EventEmitter from './EventEmitter.js';

export default class Time extends EventEmitter
{
    // Set constructor
    constructor()
    {
        // Extends the EventEmitter class
        super();
        
        // Get the current time
        this.start = Date.now();
        this.current = this.start;
        this.elapsed = 0;
        this.delta = 16;

        // Wait one frame so the delta isn't equal to 0
        window.requestAnimationFrame(() =>
        {
            // Call the tick method each frame
            this.tick()
        });
    }

    // Method called each frame to update the time variables
    tick()
    {
        // Update time variables
        const currentTime = Date.now();
        this.delta = currentTime - this.current;
        this.current = currentTime;
        this.elapsed = this.current - this.start;

        // Trigger the tick event
        this.trigger('tick');

        // Request animation frame to keep updating each frame
        window.requestAnimationFrame(() =>
        {
            this.tick()
        });
    }
}